
  import { Component, Mixins } from "vue-property-decorator";
  import mixinPage from "@/mixins/mixinPage";
  import EmployeeeachSelfGeneralZip from "@/components/employeeeach/self/general/Zip.vue";
  import EmployeeeachSelfGeneralAddress from "@/components/employeeeach/self/general/Address.vue";
  import EmployeeeachSelfGeneralTel from "@/components/employeeeach/self/general/Tel.vue";
  import EmployeeeachSelfGeneralMobile from "@/components/employeeeach/self/general/Mobile.vue";
  import LItemlevel from "@/components/l/Itemlevel.vue";
  import EmployeeeachSelfGeneralCarfaretype from "@/components/employeeeach/self/general/Carfaretype.vue";
  import EmployeeeachSelfGeneralCarfareway from "@/components/employeeeach/self/general/Carfareway.vue";
  import EmployeeeachSelfGeneralCarfarekm from "@/components/employeeeach/self/general/Carfarekm.vue";
  import EmployeeeachSelfGeneralRoute from "@/components/employeeeach/self/general/Route.vue";

  @Component({
    components: {
      EmployeeeachSelfGeneralZip,
      EmployeeeachSelfGeneralAddress,
      EmployeeeachSelfGeneralTel,
      EmployeeeachSelfGeneralMobile,
      LItemlevel,
      EmployeeeachSelfGeneralCarfaretype,
      EmployeeeachSelfGeneralCarfareway,
      EmployeeeachSelfGeneralCarfarekm,
      EmployeeeachSelfGeneralRoute,
    },
  })
  export default class Main extends Mixins(mixinPage) {
    get raw_object() {
      return this.manager.object.employee;
    }
    get tmp_object() {
      return this.manager.object.employee;
    }
  }
